<template>
  <div class="container">
    <div class="searchbox clearfix">
      <div class="searchline clearfix">
        <button class="btnicon">
          <md-icon name="search"
                   size="lg"
                   color="#D6D6D6"></md-icon>
        </button>
        <input type="text"
               placeholder="搜索课程名称"
               v-model="searchValue"
               @focus="getFocus" />
        <button class="btnclear"
                @click="clearinput"
                v-show="isclear">
          <md-icon name="fail"
                   size="lg"
                   color="#666"></md-icon>
        </button>
      </div>
      <div class="searchTxt"
           @click="search">搜索</div>
    </div>
    <div class="lesscontxt">
      <p class="lesslist"
         v-for="i in wzlist"
         :key="i.wzcode"
         @click="towzdetails(i)">
        <span>{{i.wztitle.length>15?i.wztitle.slice(0,18)+'...':i.wztitle}}</span>
        <md-icon name="arrow-right"
                 size="md"></md-icon>
      </p>
    </div>
  </div>
</template>
<script>
import { selectXbWz } from "@/api/xxb";
export default {
  data () {
    return {
      searchValue: "",
      isclear: false,
      wzlist: []
    };
  },
  methods: {
    clearinput () {
      this.searchValue = "";
      this.isclear = false;
    },
    getFocus () {
      this.isclear = true;
    },
    search () {
      if (this.searchValue !== "") {
        selectXbWz({ wzinfo: this.searchValue }).then(res => {
          this.wzlist = res.data.data
        });
      }
    },
    towzdetails (row) {
      this.$router.push({
        path: "/wzdetails",
        query: {

          wzcode: row.wzcode,

        }
      });
    }
  }
};
</script>
<style lang="stylus" scoped>
.container {
  background: white;
}

.searchbox {
  display: flex;
  height: 0.87rem;
  width: 95%;
  margin: 20px auto;
  align-items: center;
  position: relative;

  .searchline {
    border-radius: 0.5rem;
    background: #F2F2F2;
    height: 0.87rem;
    width: 85%;
  }

  .btnicon {
    width: 16%;
    height: 0.87rem;
    border-radius: 0.3rem 0 0 0.3rem;
    background: none;
  }

  input {
    height: 0.87rem;
    border: none;
    background: none;
    font-size: 0.33rem;
    font-family: PingFang-SC-Medium;
    font-weight: 500;
    color: #989898;
    position: absolute;
  }

  .btnclear {
    background: none;
    position: absolute;
    right: 1.8rem;
    line-height: 0.95rem;
  }

  .searchTxt {
    color: #989898;
    margin-left: 0.3rem;
  }
}

.lesscontxt {
  background: white;
}

.lesslist {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.39rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: rgba(56, 56, 56, 1);
  margin: 0 0.7rem 0.5rem;

  .md-icon {
    color: #9B9B9B;
  }
}

.lesslist:nth-child(1) {
  padding-top: 0.38rem;
}
</style>